import { render, staticRenderFns } from "./jobType.vue?vue&type=template&id=f80daf84&scoped=true&"
import script from "./jobType.vue?vue&type=script&lang=js&"
export * from "./jobType.vue?vue&type=script&lang=js&"
import style0 from "./jobType.vue?vue&type=style&index=0&id=f80daf84&prod&scoped=true&lang=less&"


/* normalize component */
import normalizer from "!../../../../node_modules/_vue-loader@15.10.1@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f80daf84",
  null
  
)

export default component.exports